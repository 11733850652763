import Vue from "vue";

export default {
  state: {
    patients: [],
  },
  mutations: {
    SET_PATIENTS(state, patients) {
      state.patients = patients;
    },
    ADD_PATIENT(state, patient) {
      state.patients.push(patient);
    },
    UPDATE_PATIENT(state, patient) {
      const index = state.patients.findIndex((u) => u.id === patient.id);
      Vue.set(state.patients, index, patient);
    },
    DELETE_PATIENT(state, id) {
      state.patients = state.patients.filter((u) => u.id !== id);
    },
  },
  actions: {},
};
