import Vue from "vue";

export default {
  state: {
    users: [],
  },
  mutations: {
    SET_USERS(state, users) {
      state.users = users;
    },
    ADD_USER(state, user) {
      state.users.push(user);
    },
    UPDATE_USER(state, user) {
      const index = state.users.findIndex((u) => u.id === user.id);
      Vue.set(state.users, index, user);
    },
    DELETE_USER(state, id) {
      state.users = state.users.filter((u) => u.id !== id);
    },
  },
  actions: {},
};
