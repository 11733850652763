import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import JwtService from "@/core/services/jwt.service";
import FormData from "form-data";

/**
 * Service to call HTTP request via Axios
 */
const ApiService = {
  init() {
    Vue.use(VueAxios, axios);
    // Vue.axios.defaults.baseURL = "http://localhost:3000";
    // Vue.axios.defaults.baseURL = "https://tanakagroup2.bg.ic.ac.uk:8000";
    Vue.axios.defaults.baseURL = "https://api.i4i.eczemanet.org";
  },

  /**
   * Set the default HTTP request headers
   */
  setHeader() {
    Vue.axios.defaults.headers.common[
      "Authorization"
    ] = `Token ${JwtService.getToken()}`;
    Vue.axios.defaults.headers.common[
      "X-Access-Token"
    ] = `${JwtService.getToken()}`;
    // Vue.axios.defaults.headers.common["Content-Type"] = `multipart/form-data`;
  },

  query(resource, params) {
    return Vue.axios.get(resource, params).catch((error) => {
      throw error;
    });
  },

  /**
   * Send the GET HTTP request
   * @param resource
   * @param slug
   * @returns {*}
   */
  get(resource, params) {
    return Vue.axios.get(`${resource}`, params).catch((error) => {
      throw error;
    });
  },

  /**
   * Set the POST HTTP request
   * @param resource
   * @param params
   * @returns {*}
   */
  post(resource, params) {
    return Vue.axios.post(`${resource}`, params).catch((error) => {
      throw error;
    });
  },

  /**
   * Send the UPDATE HTTP request
   * @param resource
   * @param slug
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  update(resource, slug, params) {
    return Vue.axios.put(`${resource}/${slug}`, params).catch((error) => {
      throw error;
    });
  },

  /**
   * Send the PUT HTTP request
   * @param resource
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  put(resource, params) {
    return Vue.axios.put(`${resource}`, params).catch((error) => {
      throw error;
    });
  },

  /**
   * Send the DELETE HTTP request
   * @param resource
   * @returns {*}
   */
  delete(resource) {
    return Vue.axios.delete(resource).catch((error) => {
      throw error;
    });
  },

  /**
   * Upload a file
   * @param {string} resource - The API endpoint to upload the file to
   * @param {File} file - The file to upload
   * @returns {*} - The response from the server
   */
  uploadFile(resource, file, filename) {
    // See hygieai_api/controllers/imageControllers.js for the multer diskStorage configuration
    // NOTE: On the server, in /etc/nginx/nginx.conf, we need to set client_max_body_size 100M; otherwise by default, nginx allows only 1MB per file.
    const formData = new FormData();
    formData.append("image", file, filename);
    formData.append("newfilename", filename);

    // Make a POST request to the specified resource with the FormData
    return Vue.axios.post(`${resource}`, formData, {
      headers: {
        // Set content type to multipart/form-data
        'Content-Type': 'multipart/form-data',
        // Include any necessary headers, such as authorization
        'Authorization': `Token ${JwtService.getToken()}`,
        'X-Access-Token': `${JwtService.getToken()}`,
      },
    }).catch((error) => {
      throw error;
    });
  },
};

export default ApiService;
